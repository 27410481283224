
import { useState, useEffect } from 'react';

function Login(url, pwd){

    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({pwd:pwd}),
        };


        fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                resolve(data);
            })
            .catch(err => {
                console.log(err.message);
                reject();
            });
    });

}


export default Login;