import React, {useState, useEffect} from "react";
import Requester from "../util/Requester";
import BookModel from "./BookModel";
import BookEditorComponent from "../component/BookEditorComponent";
import BookSaver from "../util/BookSaver";
import BookCreator from "../util/BookCreator";


let book_models = [];
let initial_load = false;
const host = process.env.REACT_APP_API_URL;

function Contact(){
    const [loading, setLoading] = useState(false);
    const [list_books, setListBooks] = useState([]);

    const newBook = async () => {
        try {
            setLoading(true);
            await BookCreator(host+'/api/book/add');
        }catch (error) {
            console.log(error);
        }finally {
            setLoading(false);
            initial_load = false;
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            for(let i=0; i<book_models.length; i++) {
                let resp = await BookSaver(host+'/api/book/update', book_models[i]);
            }
        }catch (error) {
            console.log(error);
        }finally {
            setLoading(false);
        }
    }
    let my_list_books = [];
    const fetchBooks = async () => {
        try {
            let bookResp = await Requester(host+'/api/book/get/all');
            if(bookResp != null) {
                for (let i = 0; i < bookResp.length; i++) {
                    let book = new BookModel().fromJson(bookResp[i]);
                    book_models.push(book);
                    my_list_books.push(<BookEditorComponent id={"book"+i.toString()} book={book}></BookEditorComponent>);
                }
                setListBooks(my_list_books);
                initial_load = true;
            }
        }catch (error) {
            console.log(error);
        }
    }
    if(initial_load === false){
        book_models = [];
        document.getElementsByTagName("body")[0].style.backgroundColor="#ffe8e8";
        fetchBooks();
    }

    return <>
    <p>Ici on edit les livres à afficher sur le site </p>
        {list_books}
        {my_list_books}
        <button onClick={newBook}>Ajouter livre</button>
        <button onClick={fetchData}>Saver les livres</button>
        {loading ? 'Chargement' : ' Etat OK'}
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>;

}

export default Contact;