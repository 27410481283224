
import Cookies from "js-cookie";

function BookSaver(url, jsonData){
    const token = Cookies.get('token');
    if(token !== null) {
        jsonData['token'] = token;

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(jsonData),
            };


            fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    resolve(data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject();
                });
        });
    }
    /*
    useEffect(()=>{

    }, [url, jsonData]);
    //});
    return (
        resp
    );

     */
}


export default BookSaver;