
import React, {useState, useEffect} from "react";
import Article from "../component/Article";
import InstagramFetcher from "../util/InstagramFetcher";


function Actualites(){

    const AfficherPlus = () => {
        setId(prevId => prevId + deltaSize); // Update id state correctly
    };
    function fetchArticles(){
        InstagramFetcher(id, deltaSize).then(r => {
            if(r){
                setArticles(prevArticles => {
                    let o = [...prevArticles]; // Use the previous articles and append new ones
                    for (let i = 0; i < r['results'].length; i++) {
                        let post = r['results'][i];
                        const description = post['description'].replace(/<a[^>]*>(.*?)<\/a>/g, '$1');
                        o.push(<Article key={post['id']} ids={post['id']} img={post['img']} description={description} date={post['date']} />);
                        if(post['id'] === 1){
                            setButton(<></>);
                        }
                    }
                    return o; // Return the updated articles list
                });
            }
        });
    }
    document.getElementsByTagName("body")[0].style.backgroundColor="#fff1ce";

    const [id, setId] = useState(0);
    let deltaSize = 3;
    const [articles, setArticles] = useState([]);
    const [button, setButton] = useState(<div className="inline-block afficher" onClick={AfficherPlus}>Afficher +</div>);

    useEffect(() => {
        fetchArticles();
        document.getElementsByTagName("body")[0].style.backgroundColor = "#fff1ce";
    }, []); // The empty array ensures useEffect runs only once when the component mounts

    useEffect(() => {
        if (id !== 0) { // Avoid fetching when `id` is initially 0
            fetchArticles();
        }
    }, [id]);

    return <>
        <h1 className="riot-font">Les dernières actualités</h1>
        <div className={"articles"}>
            {articles}
        </div>
        {button}
    </>;

}

export default Actualites;