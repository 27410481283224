
import Cookies from "js-cookie";

function Requester(url) {
    const token = Cookies.get('token');
    let jsonData = {};
    if(token !== null) {
        jsonData['token'] = token;
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(jsonData),
            };
            try {
                fetch(url, requestOptions)
                    .then((res) => res.json())
                    .then((data) => {
                        resolve(data);
                    })
                    .catch(err => {
                        console.log(err.message);
                        reject(err.message);
                    });
            }catch(error){
                console.log("Failed to fetch " + url);
                console.log(error);
                reject(error);
            }
        });
    }
}

export default Requester;