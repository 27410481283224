
import { useState, useEffect } from 'react';

const host = process.env.REACT_APP_API_URL;

function InstagramFetcher( index, count){
    let url = host + '/fetchInstagram';
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    index:index,
                    count:count
                }
            ),
        };

        fetch(url, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                resolve(data);
            })
            .catch(err => {
                console.log(err.message);
                reject();
            });
    });

}


export default InstagramFetcher;